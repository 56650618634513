form {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	width: 100%;

	.form-name {
		display: flex;
		flex-direction: row;
		column-gap: 20px;
		align-items: flex-end;

		.firstname,
		.lastname {
			width: 100%;
		}
	}

	input,
	textarea {
		background-color: transparent;
		border-radius: 4px;
		width: 100%;
		height: 46px;
		outline: none;
		box-sizing: border-box;
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 0 rbga(0, 0, 0, 0);
		transition: 0.2s;
		border: 2px solid var(--text-color);
		padding: 0 14px;
		margin: 2px 0;

		&:focus {
			outline: none;
		}
	}

	textarea {
		min-height: 200px;
		padding: 14px;
	}

	button {
		padding: 8px 24px;
		font-size: 18px;
		background-color: rgba(255, 255, 255, 0);
		background-color: var(--text-color);
		color: var(--bg-color);
		border-radius: 4px;
		margin-top: 20px;
		transition: 0.2s;
		width: 240px;
		align-self: flex-end;

		&:hover {
			color: white;
			cursor: pointer;
		}
	}
}
