#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  align-items: center;
  top: 0;
  z-index: 50;
  height: 70px;
  margin-bottom: -70px;

  nav {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;

    p {
      position: relative;

      ::after {
        display: block;
        width: 100%;
        height: 2px;
        content: " ";
        bottom: -20px;
        background-color: var(--text-accent);
        width: 0;
        transition: 0.2s ease-in-out;
        position: absolute;
        bottom: -4px;
      }

      &.current,
      &:hover {
        ::after {
          width: 100%;
        }
      }
    }
  }
}

#intro {
  .bg {
	transform: translateY(10vh);
    background-image: url("./../public/bg.png");
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 110vh;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: visible;
    background-position: center;
    opacity: var(--opacity);
  }

  h1 {
    line-height: 1.15;
  }
}

#work {
  .preview {
    position: fixed;
    width: 300px;
    height: 300px;
    overflow: hidden;
    pointer-events: none;
    transform-origin: center;
    z-index: 2;

    .preview-img {
      width: 100%;
      height: 100%;
      background: url("./../public/sprite.png");
      background-size: 300px;
      background-position: 0 0;
      pointer-events: none;
    }
  }
}
